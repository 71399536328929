import React, { useEffect, useState } from "react";
import OAuthLink from "../../components/updated/authentication/OAuthLink";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  Container: {
    width: "100%",
    display: "flex",
    marginTop: "100px",
    justifyContent: "center",
    alignItems: "center",
    background: theme?.palette?.background.default,
    color: theme?.palette?.text?.primary,
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  orderBy: {
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
}));

const OAuthLinkPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.Container}>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <OAuthLink />
      </div>
    </div>
  );
};

export default OAuthLinkPage;
