import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import GenericButton from "../Button";
import passwordChanged from "../../../../assets/popup/loginPage.png";
import { CircularProgress } from "@mui/material";

import updatedLotto from "../../../../assets/homepage/updated/lotto.png";

import wallet from "../../../../assets/homepage/updated/wallet.png";

import academy from "../../../../assets/homepage/xacademy.png";
import academyLightMode from "../../../../assets/updated/homePage/academyLightMode.png";

import aii from "../../../../assets/homepage/updated/aii.png";

import updatedExch from "../../../../assets/homepage/updated/exchange.png";
import updatedExchLightMode from "../../../../assets/updated/homePage/exchangeLightMode.png";

import shop from "../../../../assets/homepage/updated/shop.png";
import shopLightMode from "../../../../assets/updated/homePage/shopLightMode.png";

import token from "../../../../assets/homepage/updated/token.png";
import tokenLightMode from "../../../../assets/updated/homePage/tokenLightMode.png";

import hive from "../../../../assets/homepage/updated/hive.png";
import smartCrypto from "../../../../assets/homepage/updated/Smartcryptologo.svg";
import cryptoTreasury from "../../../../assets/homepage/updated/treasury.svg";

import meme from "../../../../assets/homepage/updated/meme.png";
import CloseIcon from "@mui/icons-material/Close";
import { transform } from "typescript";
import { useTheme } from "@mui/material";
import { baseNewCEXURL, getUserShortToken } from "../../../../services/api";

const useStyles = makeStyles((theme) => ({
  // Existing styles...
  dataShow: {
    opacity: "1 !important",
    visibility: "visible !important",
    "& .bnModalWrap": {
      transform: "scale(1) !important",
    },
  },
  bidsFullModal: {},
  bnMask: {
    alignItems: "center",
    backgroundColor: " rgba(0, 0, 0, .5)",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 1200,
    width: "100%",
    height: "100vh",
  },
  bnTrans: {
    opacity: 0,
    transitionDuration: "250ms",
    transitionProperty: "all",
    transitionTimingFunction: "ease-in-out",
    visibility: "hidden",
  },
  bnModal: {
    "& .bnModalWrap": {
      backgroundColor: theme?.palette?.mode === "light" ? "#ffffff" : "#1e2329",
      borderRadius: "16px",
      boxShadow: "0px 3px 6px rgba(0,0,0,.04)",
      overflow: "hidden",
      position: "relative",
      transform: "scale(.9)",
      transitionDuration: "250ms",
      transitionProperty: "all",
      transitionTimingFunction: "ease-in-out",
      maxWidth: "760px",
      width: "100%",
      marginTop: "70px",
      maxHeight: "90vh",
      overflowY: "auto",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "32px 16px",
    textAlign: "center",
    color: `${theme?.palette?.text?.primary} !important`,
    "& h3": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "38px",
      fontWeight: 600,

      marginTop: "-10px",
    },
    "& h4": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      margin: "10px 0px 15px 0px",
    },
    "& p": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
    },
    [theme?.breakpoints?.down("sm")]: {
      height: "auto",
      overflow: "auto",
    },
  },
  btnContainer: {
    display: "flex",
    width: "100%",
    gap: "15px",
    marginTop: "25px",
  },
  cancelButton: {
    background:
      theme?.palette?.mode === "light"
        ? "#EAECEF !important"
        : "#2B3139 !important",
    color: `${theme?.palette?.text?.primary} !important`,
  },
  howItWorksButton: {
    background: "none !important",
    color: `#11BE6A !important`,
    border: `1px solid #11BE6A !important`,
  },
  featureContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "20px",
    gap: "20px",
    marginBottom: "20px",
  },
  featureItem: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    alignItems: "center",
    width: "155px",
    transition: "all .2s linear",
    textAlign: "center",
    "& img": {
      height: "40px",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "13px",
      color: `${theme?.palette?.text?.primary}`,
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}));

const LoginPopup = ({
  onClose,
  onExploreIndexx,
  onBuyToken,
  redirectWebsiteLink,
  email,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  // Map URLs to their corresponding names
  const urlNameMap = {
    "shop.indexx.ai": "Shop",
    "cex.indexx.ai": "Exchange",
    "lotto.indexx.ai": "Fantasy Lotto",
    "academy.indexx.ai": "Academy",
  };

  const getRedirectName = (url) => {
    try {
      const hostname = new URL(url).hostname;
      return urlNameMap[hostname] || "the site"; // Default name in case the hostname doesn't match
    } catch (error) {
      console.error("Invalid URL:", url);
      return "the site";
    }
  };

  const redirectName = redirectWebsiteLink
    ? getRedirectName(redirectWebsiteLink)
    : "";
  const handleClick = async (path) => {
    const shortToken = await getUserShortToken(email);

    if (path === "https://cex.indexx.ai") {
      window.location.href = `https://cex.indexx.ai/update/home?buyToken=INEX&signInToken=${shortToken?.data}`;
      return;
    }
    const urlObj = new URL(path);
    urlObj.searchParams.set("signInToken", shortToken?.data);
    window.location.href = urlObj.toString();
  };

  const redirectionFunction = async () => {
    console.log(redirectWebsiteLink, "redirectWebsiteLink");
    const shortToken = await getUserShortToken(email);
    const urlObj = new URL(redirectWebsiteLink);
    urlObj.searchParams.set("signInToken", shortToken?.data);
    window.location.href = urlObj.toString();
  };

  useEffect(() => {
    if (redirectWebsiteLink) {
      redirectionFunction();
    }
  }, [redirectWebsiteLink]);
  return (
    <div
      className={`${classes.bnTrans} ${classes.dataShow} ${classes.bnMask} ${classes.bnModal}  ${classes.bidsFullModal}`}
    >
      <div className="bnModalWrap">
        <div className={classes.contentContainer}>
          {redirectWebsiteLink ? (
            <>
              <h4>Please wait while we redirect you to {redirectName}</h4>
              <CircularProgress sx={{ color: "#11BE6A" }} />
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div></div>

                <div onClick={onClose} style={{ cursor: "pointer" }}>
                  <CloseIcon
                    color={theme.palette.text.secondary}
                    sx={{
                      "&:hover": {
                        opacity: ".7",
                      },
                    }}
                  />
                </div>
              </div>
              <img
                src={passwordChanged}
                height="200px"
                alt="congratulation icon"
              />
              <h3>Congratulations!</h3>
              <p>You’ve just Logged In.</p>
              <p>
                Explore indexx by clicking on one of the platforms below to get
                started.
              </p>

              {/* Flex container with icons and text */}
              <div
                className={classes.featureContainer}
                style={{ marginTop: "50px" }}
              >
                <div
                  className={classes.featureItem}
                  onClick={() => handleClick("https://cex.indexx.ai")}
                >
                  <img
                    src={
                      theme.palette.mode === "dark"
                        ? updatedExch
                        : updatedExchLightMode
                    }
                    alt="Feature 1"
                  />
                  <p>Exchange / Buy Crypto</p>
                </div>
                <div
                  className={classes.featureItem}
                  onClick={() => handleClick("https://lotto.indexx.ai")}
                >
                  <img src={updatedLotto} alt="Feature 2" />
                  <p>Fantasy Lotto</p>
                </div>
                <div
                  className={classes.featureItem}
                  onClick={() => handleClick("https://shop.indexx.ai")}
                >
                  <img
                    src={theme.palette.mode !== "dark" ? shopLightMode : shop}
                    alt="Feature 3"
                  />
                  <p>Shop</p>
                </div>
                <div
                  className={classes.featureItem}
                  onClick={() =>
                    handleClick(
                      "https://indexx.ai/indexx-exchange/token-details"
                    )
                  }
                >
                  <img
                    src={theme.palette.mode === "dark" ? token : tokenLightMode}
                    alt="Feature 4"
                  />
                  <p>xTokens</p>
                </div>
              </div>
              <div className={classes.featureContainer}>
                <div
                  className={classes.featureItem}
                  onClick={() => handleClick("https://hive.indexx.ai")}
                >
                  <img src={hive} alt="Feature 1" />
                  <p>Hive</p>
                </div>
                <div
                  className={classes.featureItem}
                  onClick={() => handleClick("https://academy.indexx.ai")}
                >
                  <img
                    src={
                      theme.palette.mode === "dark" ? academy : academyLightMode
                    }
                    alt="Feature 2"
                  />
                  <p>Academy</p>
                </div>
                <div
                  className={classes.featureItem}
                  onClick={() =>
                    handleClick("https://cex.indexx.ai/crypto-treasury")
                  }
                >
                  <img src={cryptoTreasury} alt="Feature 3" />
                  <p>Crypto Treasury</p>
                </div>
                <div
                  className={classes.featureItem}
                  onClick={() => handleClick("https://wallet.indexx.ai/")}
                >
                  <img src={meme} alt="Feature 4" />
                  <p>Memeniac</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
