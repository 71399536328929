import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import InputField from "../../shared/TextField";
import GenericButton from "../../shared/Button";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import lightModeLogo from "../../../../assets/header-icons/indexx grey.svg";
import darkModeLogo from "../../../../assets/header-icons/new indexx.ai logo.svg";
import { useNavigate } from "react-router-dom";
import googleLogo from "../../../../assets/authentication/logogoogle.svg";
import appleLogo from "../../../../assets/authentication/ios.svg";
import iosDark from "../../../../assets/authentication/ios-dark.svg";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useGoogleLogin } from "@react-oauth/google";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  baseNewCEXURL,
  // checkEmail,
  checkByemail,
  decodeJWT,
  getUserShortToken,
  loginWithGoogle,
} from "../../../../services/api";
import LoginPopup from "../../shared/LoginPopup";
import RedirectionPopup from "../../shared/RedirectionPopup";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("myTotallySecretKey");

const useStyles = makeStyles((theme) => ({
  Container: {
    border: `1px solid ${theme?.palette?.divider}`,
    borderRadius: "24px",
    minHeight: "580px",
    padding: "40px",
    maxWidth: "425px",
    width: "100%",
    [theme?.breakpoints?.down("md")]: {
      border: "none",
      width: "100%",
    },
  },
  socialButton: {
    background: "none !important",
    border: `1px solid ${theme?.palette?.divider} !important`,
    margin: "10px 0px !important",
    color: `${theme?.palette?.text?.primary} !important`,
  },
  loginText: {
    fontSize: "32px",
    fontWeight: 600,
    color: `${theme?.palette?.text?.primary} !important`,
    marginBottom: "30px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoText: {
    color: `${theme?.palette?.text?.primary} !important`,
    fontSize: "18px",
    fontWeight: 600,
    margin: 0,
  },
  createLink: {
    color: `${theme?.palette?.primary?.main} !important`,
    background: `${theme?.palette?.background?.default} !important`,
  },
  errorText: {
    color: theme?.palette?.error?.main,
    marginTop: "8px",
  },
}));

const LoginComponent = ({ redirectWebsiteLink, referral }) => {
  const theme = useTheme();
  console.log("theme", theme);
  const classes = useStyles(theme); // Pass theme to useStyle
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showRedirectionPopup, setShowRedirectionPopup] = useState(false);
  const [shortToken, setShortToken] = useState("");
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setErrorMessage("");
    console.log("e", e.target.value);
    const value = e.target.value;
    setEmail(value);
    formik.setFieldValue("email", value);
    console.log("validateEmail(value)", validateEmail(value));
    setIsEmailValid(validateEmail(value));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNextClick = async () => {
    if (isEmailValid) {
      setLoadings(true);
      localStorage.setItem("email", email);
      const res = await checkByemail(String(email).toLowerCase());
      console.log(res, "res");
      console.log(res);
      if (res.status === 200 && !res.success) {
        setLoadings(false);
        navigate("/auth/login-password", {
          state: {
            email,
            redirectWebsiteLink,
            referral,
            userType: res?.userType,
          },
        });
      } else {
        setLoadings(false);
        setErrorMessage("Indexx Account not found");
        console.log("res", res.status);
      }
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values: ", values);
    },
  });

  const handleCreateAccountClick = () => {
    navigate("/auth/signup-email");
  };

  const handleGoogleSuccess = async (tokenResponse) => {
    console.log("tokenResponse", tokenResponse);
    setLoadings(true);
    setErrorMessage("");
    
    try {
      // Clear all existing auth data to prevent cross-account contamination
      localStorage.removeItem("shortToken");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      localStorage.removeItem("email");
      localStorage.removeItem("userType");
      localStorage.removeItem("username");
      localStorage.removeItem("userlogged");
      localStorage.removeItem("googleAuth");
      localStorage.removeItem("authTimestamp");
      
      // Make API call with fresh Google token
      const res = await loginWithGoogle(tokenResponse?.access_token);

      if (res.status === 200) {
        // Decode user information first
        let resObj = await decodeJWT(res.data.access_token);
        const userEmail = resObj?.email;
        
        if (!userEmail) {
          setLoadings(false);
          setErrorMessage("Could not retrieve email from authentication response");
          return;
        }
        
        // Generate short token immediately
        const shortTokenRes = await getUserShortToken(userEmail);
        const shortToken = shortTokenRes?.data;
        
        if (!shortToken) {
          throw new Error("Failed to generate authentication token");
        }
        
        console.log("Generated short token:", shortToken);
        
        // Store all auth data in localStorage
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("user", userEmail);
        localStorage.setItem("email", userEmail);
        localStorage.setItem("userType", resObj?.userType || "Indexx Exchange");
        localStorage.setItem("username", resObj?.username || "");
        localStorage.setItem("shortToken", shortToken);
        localStorage.setItem("googleAuth", "true");
        localStorage.setItem("authTimestamp", Date.now().toString());
        localStorage.setItem(
          "userlogged",
          resObj?.userType === "Indexx Exchange"
            ? "normal"
            : resObj?.userType === "CaptainBee"
            ? "captain"
            : "honeyb"
        );
        
        // Set state for component and ensure it's updated
        setShortToken(shortToken);
        
        // Allow localStorage to persist
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Handle redirection based on source
        if (redirectWebsiteLink) {
          // For explicit redirections like exchange, academy, etc.
          if (redirectWebsiteLink.includes("academy")) {
            // For academy, direct redirect with token
            const academyUrl = new URL(redirectWebsiteLink);
            academyUrl.searchParams.set("signInToken", shortToken);
            console.log("Redirecting to academy with token:", shortToken);
            // Show popup and redirect directly
            setShowRedirectionPopup(true);
            
            setTimeout(() => {
              window.location.href = academyUrl.toString();
            }, 2000);
          } else {
            // For other redirects, use login popup
            console.log("Showing login popup with token:", shortToken);
            setShowLoginPopup(true);
            
            // Perform the redirect directly without relying on popup components
            setTimeout(() => {
              setShowLoginPopup(false);
              const redirectUrl = new URL(redirectWebsiteLink);
              redirectUrl.searchParams.set("signInToken", shortToken);
              console.log("Redirecting to:", redirectUrl.toString());
              window.location.href = redirectUrl.toString();
            }, 2000);
          }
        } else {
          // Default case - no redirect URL specified
          setShowLoginPopup(true);
          
          setTimeout(() => {
            setShowLoginPopup(false);
            navigate("/");
            // Force a reload to ensure app recognizes new auth state
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }, 2000);
        }
        
        setLoadings(false);
      } else {
        setLoadings(false);
        setErrorMessage(res.data?.message || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Google login error:", error);
      setLoadings(false);
      setErrorMessage(error.message || "An error occurred during login. Please try again later.");
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: (error) => setErrorMessage("Login Failed"),
  });

  const handleExploreIndexx = () => {
    setShowLoginPopup(false);
    navigate("/");
  };

  const handleBuyToken = () => {
    setShowLoginPopup(false);
    window.location.href = `${baseNewCEXURL}?buyToken=INEX&signInToken=${shortToken}`;
  };

  const handleAcademyRedirect = () => {
    const urlObj = new URL(redirectWebsiteLink);
    urlObj.searchParams.set("signInToken", shortToken);
    window.location.href = urlObj.toString();
    console.log("urlObj.toString();", urlObj.toString());
  };

  return (
    <div className={classes.Container}>
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          onExploreIndexx={handleExploreIndexx}
          onBuyToken={handleBuyToken}
          redirectWebsiteLink={redirectWebsiteLink}
        />
      )}
      {showRedirectionPopup && (
        <RedirectionPopup
          name="academy"
          onClose={() => setShowRedirectionPopup(false)}
          onRedirect={handleAcademyRedirect}
        />
      )}
      {/* <div className={classes.logoContainer}>
        <img
          src={theme?.palette?.mode === "light" ? lightModeLogo : darkModeLogo}
          style={{ width: "120px" }}
        /> */}
      {/* <h2 className={classes.logoText}>Indexx.ai</h2> */}
      {/* </div> */}

      <h3 className={classes.loginText}>Log in</h3>
      <div style={{ margin: "15px auto" }}>
        <InputField
          label={"Email"}
          type="text"
          value={formik.values.email}
          onChange={handleEmailChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
          helperText={formik.errors.email}
        />
        {errorMessage && <p className={classes.errorText}>{errorMessage}</p>}
      </div>
      <GenericButton
        text={"Next"}
        loading={loadings}
        onClick={() => {
          formik.handleSubmit();
          handleNextClick();
        }}
      />

      <div style={{ margin: "10px auto" }}></div>
      <Divider>or</Divider>
      <GenericButton
        text={"Continue with Google"}
        className={classes.socialButton}
        IconComponent={
          <img
            src={googleLogo}
            style={{ width: "100%", height: "100%", marginTop: "-8px" }}
          />
        }
        onClick={() => login()}
      />
      {/* <GenericButton
        text={"Continue with Apple"}
        className={classes.socialButton}
        IconComponent={
          <img
            src={theme.palette.mode === "dark" ? appleLogo : iosDark}
            style={{ width: "100%", height: "100%", marginTop: "-8px" }}
          />
        }
        onClick={() => login()}
      />
      <GenericButton
        text={"Continue with Telegram"}
        className={classes.socialButton}
        IconComponent={
          <TelegramIcon
            sx={{
              width: "20px",
              height: "20px",
              marginTop: "-8px",
              color: "#00AEED",
            }}
          />
        }
        onClick={() => login()}
      /> */}

      <div style={{ margin: "20px auto" }}></div>

      <GenericButton
        text={"Create an Indexx Account"}
        className={classes.createLink}
        onClick={handleCreateAccountClick}
      />
    </div>
  );
};

export default LoginComponent;
