import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import InputField from "../../shared/TextField";
import GenericButton from "../../shared/Button";
import { useTheme } from "@mui/material/styles";

import { useFormik } from "formik";
import * as Yup from "yup";
const useStyles = makeStyles((theme) => ({
  Container: {
    border: `1px solid ${theme?.palette?.divider}`,
    borderRadius: "24px",
    minHeight: "580px",
    padding: "40px",
    maxWidth: "425px",
    width: "100%",
    [theme?.breakpoints?.down("md")]: {
      border: "none",
      width: "100%",
    },
  },
  socialButton: {
    background: "none !important",
    border: `1px solid ${theme?.palette?.divider} !important`,
    margin: "10px 0px !important",
    color: `${theme?.palette?.text?.primary} !important`,
  },
  loginText: {
    fontSize: "32px",
    fontWeight: 600,
    color: `${theme?.palette?.text?.primary} !important`,
    marginBottom: "20px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoText: {
    color: `${theme?.palette?.text?.primary} !important`,
    fontSize: "18px",
    fontWeight: 600,
    margin: 0,
  },
  createLink: {
    color: `${theme?.palette?.primary?.main} !important`,
    background: `${theme?.palette?.background?.default} !important`,
  },
  errorText: {
    color: theme?.palette?.error?.main,
    marginTop: "8px",
  },
}));

const OAuthLink = () => {
  const theme = useTheme();
  const classes = useStyles(theme); // Pass theme to useStyle
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e) => {
    setErrorMessage("");
    const value = e.target.value;
    setEmail(value);
    formik.setFieldValue("email", value);
    setIsEmailValid(validateEmail(value));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values: ", values);
    },
  });

  return (
    <div className={classes.Container}>
      <h3 className={classes.loginText}>Link Existing Account</h3>
      <p style={{ marginBottom: "40px", opacity: ".8" }}>
        This email has been registerd. You can verify and link it to your
        third-party account.
      </p>
      <div style={{ margin: "15px auto" }}>
        <InputField
          label={"Email"}
          type="text"
          value={formik.values.email}
          onChange={handleEmailChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
          helperText={formik.errors.email}
        />
        {errorMessage && <p className={classes.errorText}>{errorMessage}</p>}
      </div>
      <GenericButton
        text={"Confirm"}
        loading={loadings}
        onClick={() => {
          formik.handleSubmit();
        }}
      />
    </div>
  );
};

export default OAuthLink;
